/*
 * SWA OAuth client library.
 */
import { AppAuthError, AuthorizationListener, Crypto, UserInfoRequestHandler } from '..';
import { AuthorizationRequest } from '../authorization_request';
import { AuthorizationNotifier, AuthorizationRequestHandler } from '../authorization_request_handler';
import { AuthorizationServiceConfiguration } from '../authorization_service_configuration';
import { RevokeTokenRequest } from '../revoke_token_request';
import { GRANT_TYPE_AUTHORIZATION_CODE, GRANT_TYPE_REFRESH_TOKEN, TokenRequest } from '../token_request';
import { TokenRequestHandler } from '../token_request_handler';
import { StringMap } from '../types';
import { Requestor } from '../xhr';
import { IntrospectionRequest } from './introspection_request';
import { IntrospectionRequestHandler } from './introspection_request_handler';

export const GRANT_TYPE_CLIENT_CREDENTIALS = 'client_credentials';

/**
 * API for OAuth 2.0/OpenID Connect operations.
 */
export class OAuth {
  private readonly clientId: string;
  private readonly clientSecret: string | undefined;
  private readonly openIdConnectUrl: string;
  private readonly requestor: Requestor;
  private readonly authorizationRequestHandler: AuthorizationRequestHandler;
  private readonly tokenRequestHandler: TokenRequestHandler;
  private readonly userInfoRequestHandler: UserInfoRequestHandler;
  private readonly introspectionRequestHandler: IntrospectionRequestHandler;
  private configuration: AuthorizationServiceConfiguration | undefined;
  private readonly usePkce;
  private readonly crypto: Crypto;

  constructor(
    clientId: string,
    clientSecret: string | undefined,
    openIdConnectUrl: string,
    requestor: Requestor,
    authorizationRequestHandler: AuthorizationRequestHandler,
    tokenRequestHandler: TokenRequestHandler,
    userInfoRequestHandler: UserInfoRequestHandler,
    introspectionHandler: IntrospectionRequestHandler,
    usePkce = true,
    crypto: Crypto) {

    this.clientId = clientId;
    this.clientSecret = clientSecret;
    this.openIdConnectUrl = openIdConnectUrl;
    this.requestor = requestor;
    this.authorizationRequestHandler = authorizationRequestHandler;
    this.tokenRequestHandler = tokenRequestHandler;
    this.userInfoRequestHandler = userInfoRequestHandler;
    this.introspectionRequestHandler = introspectionHandler;
    this.usePkce = usePkce;
    this.crypto = crypto;
  }

  /**
   * Get information from the OpenID Provider (OP) configuration endpoint.
   */
  async fetchAuthorizationServiceConfiguration(): Promise<AuthorizationServiceConfiguration> {
    const response = await AuthorizationServiceConfiguration.fetchFromIssuer(this.openIdConnectUrl, this.requestor);
    this.configuration = response;
    return response;
  }

  /**
   * Make the authorization request.
   *
   * @param {string} redirectUri - The application callback URI.
   * @param {string} scope - Space separated scopes e.g. 'openid email profile'.
   * @param {string} [state] - An opaque value maintaining state between request and callback.
   * @param {string} [audience] - The audience is the resource URI the client wants to access.
   * @param {StringMap} [extras] - Additional parameters to be added to the request.
   */
  authorize(redirectUri: string, scope: string, state?: string, audience?: string, extras?: StringMap) {
    if (!extras) {
      extras = {};
    }
    if (audience) {
      extras[Extras.aud] = audience;
    }

    const request = new AuthorizationRequest(
      {
        client_id: this.clientId,
        redirect_uri: redirectUri,
        scope,
        response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
        state,
        extras
      },
      this.crypto,
      this.usePkce);

    try {
      this.authorizationRequestHandler.performAuthorizationRequest(this.configuration!, request);
    } catch (e) {
      throw new AppAuthError('Error during authorization', e);
    }
  }

  /**
   * Completes the authorization request if necessary & when possible.
   */
  checkForAuthorizationResponse() {
    this.authorizationRequestHandler.completeAuthorizationRequestIfPossible();
  }

  /**
   * Create the authorization request URL.
   *
   * @param {string} redirectUri - The application callback URI.
   * @param {string} scope - Space separated scopes e.g. 'openid email profile'.
   * @param {string} [state] - An opaque value maintaining state between request and callback.
   * @param {string} [audience] - The audience is the resource URI the client wants to access.
   * @param {StringMap} [extras] - Additional parameters to be added to the request.
   */
  async createAuthorizationRequestUrl(redirectUri: string, scope: string, state?: string, audience?: string, extras?: StringMap): Promise<string> {
    if (!extras) {
      extras = {};
    }
    if (audience) {
      extras[Extras.aud] = audience;
    }

    const request = new AuthorizationRequest(
      {
        client_id: this.clientId,
        redirect_uri: redirectUri,
        scope,
        response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
        state,
        extras
      },
      this.crypto,
      this.usePkce);

    try {
      return await this.authorizationRequestHandler.createAuthorizationRequestUrl(this.configuration!, request);
    } catch (e) {
      throw new AppAuthError('Error creating authorization URL', e);
    }
  }

  /**
   * Exchange authorization code for tokens.
   *
   * @param {string} code - Authorization code.
   * @param {string} redirectUri - The application callback URI.
   * @param {string} [audience] - The audience is the resource URI the client wants to access.
   * @param {StringMap} [extras] - Additional parameters to be added to the request.
   */
  async authorizationCodeExchange(code: string, redirectUri: string, codeVerifier?: string, audience?: string, extras?: StringMap) {
    if (!extras) {
      extras = {};
    }
    if (this.clientSecret) {
      extras[Extras.clientSecret] = this.clientSecret;
    }
    if (audience) {
      extras[Extras.aud] = audience;
    }
    if (codeVerifier) {
      extras[Extras.codeVerifier] = codeVerifier;
    }

    const request = new TokenRequest({
      client_id: this.clientId,
      redirect_uri: redirectUri,
      grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
      code,
      refresh_token: undefined,
      extras
    });

    return await this.tokenRequestHandler.performTokenRequest(this.configuration!, request);
  }

  /**
   * Request a new access token, using the refresh token.
   *
   * @param {string} refreshToken - The refresh token.
   * @param {string} redirectUri - The application callback URI.
   * @param {StringMap} [extras] - Additional parameters to be added to the request.
   */
  async refreshToken(refreshToken: string, redirectUri: string, extras?: StringMap) {
    if (!extras) {
      extras = {};
    }
    if (this.clientSecret) {
      extras[Extras.clientSecret] = this.clientSecret;
    }

    const request = new TokenRequest({
      client_id: this.clientId,
      redirect_uri: redirectUri,
      grant_type: GRANT_TYPE_REFRESH_TOKEN,
      code: undefined,
      refresh_token: refreshToken,
      extras
    });

    return await this.tokenRequestHandler.performTokenRequest(this.configuration!, request);
  }

  /**
   * Revoke the given token.
   *
   * @param {string} refreshToken - The refresh token.
   */
  async revokeToken(refreshToken: string) {

    const request = new RevokeTokenRequest({
      token: refreshToken,
      client_id: this.clientId,
      client_secret: this.clientSecret
    });

    return await this.tokenRequestHandler.performRevokeTokenRequest(this.configuration!, request);
  }

  /**
   * Request user information.
   *
   * @param {string} accessToken - The access token.
   */
  async userInfo(accessToken: string) {
    return await this.userInfoRequestHandler.performUserInfoRequest(this.configuration!, accessToken);
  }

  /**
   * Perform token introspection.
   *
   * @param {string} accessToken - The access token.
   */
  async introspection(accessToken: string) {
    const request = new IntrospectionRequest({
      client_id: this.clientId,
      client_secret: this.clientSecret!,
      token: accessToken
    });

    return await this.introspectionRequestHandler.performIntrospectionRequest(this.configuration!, request, accessToken);
  }

  /**
   * Exchanges client credentials for tokens following https://tools.ietf.org/html/rfc6749#section-1.3.4.
   *
   * @param {string} scope - Space separated scopes e.g. 'openid email profile'.
   * @param {StringMap} [extras] - Additional parameters to be added to the request.
   */
  async clientCredentialsGrant(scope: string, extras?: StringMap) {
    if (!extras) {
      extras = {};
    }
    if (this.clientSecret) {
      extras[Extras.clientSecret] = this.clientSecret;
    }
    extras[Extras.scope] = scope;

    const request = new TokenRequest({
      grant_type: GRANT_TYPE_CLIENT_CREDENTIALS,
      redirect_uri: "",
      client_id: this.clientId,
      extras
    });

    return await this.tokenRequestHandler.performTokenRequest(this.configuration!, request);
  }

  /**
   * Sets the callback notifier for completion of the authorization request.
   *
   * @param {AuthorizationNotifier} notifier - Notifier to deliver authorization result.
   */
  setAuthorizationNotifier(notifier: AuthorizationNotifier) {
    this.authorizationRequestHandler.setAuthorizationNotifier(notifier);
  }

  /**
   * Sets the callback listener for completion of the authorization request.
   * 
   * This is a convenience method that wraps the listener in the default notifier.
   *
   * @param {AuthorizationListener} listener - Listener to deliver authorization result.
   */
  setAuthorizationListener(listener: AuthorizationListener) {
    const notifier = new AuthorizationNotifier();
    notifier.setAuthorizationListener(listener);
    this.setAuthorizationNotifier(notifier);
  }

}

/**
 * Helper function to get the PKCE code verifier from an authorization request.
 */
export function getCodeVerifierFromAuthorizationRequest(request: AuthorizationRequest) {
  let codeVerifier: string | undefined;
  if (request && request.internal) {
    codeVerifier = request.internal[Extras.codeVerifier];
  }
  return codeVerifier;
}

/**
 * Names of AppAuth extra parameters passed on API requests.
 */
enum Extras {
  aud = "aud",
  clientSecret = "client_secret",
  codeVerifier = "code_verifier",
  scope = "scope"
}
