import { AuthorizationServiceConfiguration } from '../authorization_service_configuration';
import { AppAuthError } from '../errors';
import { BasicQueryStringUtils, QueryStringUtils } from '../query_string_utils';
import { JQueryRequestor, Requestor } from '../xhr';
import { UserInfoError, UserInfoErrorJson, UserInfoResponse, UserInfoResponseJson } from './userinfo_response';

/**
 * Represents an interface which can make a user info request.
 */
export interface UserInfoRequestHandler {
  /**
   * Performs the user info request, given the service configuration.
   */
  performUserInfoRequest(configuration: AuthorizationServiceConfiguration, accessToken: string):
    Promise<UserInfoResponse>;
}

/**
 * The default user info request handler.
 */
export class BaseUserInfoRequestHandler implements UserInfoRequestHandler {
  constructor(
    public readonly requestor: Requestor = new JQueryRequestor(),
    public readonly utils: QueryStringUtils = new BasicQueryStringUtils()) { }

  private isUserInfoResponse(response: UserInfoResponseJson |
    UserInfoErrorJson): response is UserInfoResponseJson {
    return (response as UserInfoErrorJson).error === undefined;
  }

  performUserInfoRequest(configuration: AuthorizationServiceConfiguration, accessToken: string):
    Promise<UserInfoResponse> {
    const userInfoResponse = this.requestor.xhr<UserInfoResponseJson | UserInfoErrorJson>({
      url: configuration.userInfoEndpoint,
      method: 'GET',
      dataType: 'json',
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + accessToken }
    });

    return userInfoResponse.then(response => {
      if (this.isUserInfoResponse(response)) {
        return new UserInfoResponse(response);
      } else {
        return Promise.reject<UserInfoResponse>(
          new AppAuthError(response.error, new UserInfoError(response)));
      }
    });
  }
}
