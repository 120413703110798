import { StringMap } from '../types';

export interface IntrospectionResponseJson {
  sub: string;
  groups: string;
  cn: string;
  iss: string;
  active: boolean;
  token_type: string;
  aud: string;
  auth_time: number;
  exp: number;
  iat: number;
  jti: string;
  ito: number;
  client_id: string;
  nbf: number;
  scope: string;
}

/**
 * Represents the Introspection Response type.
 * For more information see https://tools.ietf.org/html/rfc6749#section-5.1
 */
export class IntrospectionResponse {
  sub: string;
  ito: number;
  active: boolean;
  groups: string;
  cn: string;
  tokenType: string;
  aud: string;
  authTime: number;
  exp: number;
  iat: number;
  jti: string;
  iss: string;
  scope: string;
  clientId: string;
  nbf: number;

  constructor(response: IntrospectionResponseJson) {
    this.sub = response.sub;
    this.ito = response.ito;
    this.active = response.active;
    this.scope = response.scope;
    this.clientId = response.client_id;
    this.nbf = response.nbf;
    this.groups = response.groups;
    this.cn = response.cn;
    this.aud = response.aud;
    this.iss = response.iss;
    this.iat = response.iat;
    this.authTime = response.auth_time;
    this.jti = response.jti;
    this.exp = response.exp;
    this.tokenType = response.token_type;
  }

  toJson(): IntrospectionResponseJson {
    return {
      sub: this.sub,
      cn: this.cn,
      iss: this.iss,
      iat: this.iat,
      exp: this.exp,
      token_type: this.tokenType,
      jti: this.jti,
      active: this.active,
      auth_time: this.authTime,
      aud: this.aud,
      ito: this.ito,
      client_id: this.clientId,
      nbf: this.nbf,
      scope: this.scope,
      groups: this.groups,
    };
  }

  toStringMap(): StringMap {
    return {
      sub: this.sub,
      cn: this.cn,
      iss: this.iss,
      iat: this.iat.toString(),
      exp: this.exp.toString(),
      token_type: this.tokenType,
      jti: this.jti,
      active: String(this.active),
      auth_time: this.authTime.toString(),
      aud: this.aud,
      ito: this.ito.toString(),
      client_id: this.clientId,
      nbf: this.nbf.toString(),
      scope: this.scope,
      groups: this.groups,
    };
  }
}

/**
 * Represents the Instrospection Error type.
 * For more information see https://tools.ietf.org/html/rfc6749#section-5.2
 */
export class IntrospectionError {
  error: IntrospectionErrorType;
  errorDescription: string | undefined;
  errorUri: string | undefined;

  constructor(error: IntrospectionErrorJson) {
    this.error = error.error;
    this.errorDescription = error.error_description;
    this.errorUri = error.error_uri;
  }

  toJson(): IntrospectionErrorJson {
    return {
      error: this.error,
      error_description: this.errorDescription,
      error_uri: this.errorUri,
    };
  }
}

/**
 * Represents the possible error codes from the introspection endpoint.
 * For more information see https://tools.ietf.org/html/rfc6749#section-5.2
 */
export type IntrospectionErrorType = 'invalid_request' | 'invalid_client' | 'invalid_grant' |
  'unauthorized_client' | 'unsupported_grant_type' | 'invalid_scope';

/**
 * Represents the IntrospectionError as a JSON Object.
 */
export interface IntrospectionErrorJson {
  error: IntrospectionErrorType;
  error_description?: string;
  error_uri?: string;
}
