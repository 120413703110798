/*
 * Copyright 2017 Google Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use this file except
 * in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {LocationLike, StringMap} from './types';

const MULTI_VALUE_PARAMETERS = ['aud', 'resource'];
/**
 * Query String Utilities.
 */
export interface QueryStringUtils {
  stringify(input: StringMap): string;
  parse(query: LocationLike, useHash?: boolean): StringMap;
  parseQueryString(query: string): StringMap;
}

export class BasicQueryStringUtils implements QueryStringUtils {
  parse(input: LocationLike, useHash?: boolean) {
    if (useHash) {
      return this.parseQueryString(input.hash);
    } else {
      return this.parseQueryString(input.search);
    }
  }

  parseQueryString(query: string): StringMap {
    let result: StringMap = {};
    // if anything starts with ?, # or & remove it
    query = query.trim().replace(/^(\?|#|&)/, '');
    let params = query.split('&');
    for (let i = 0; i < params.length; i += 1) {
      let param = params[i];  // looks something like a=b
      let parts = param.split('=');
      if (parts.length >= 2) {
        let key = decodeURIComponent(parts.shift()!);
        let value = parts.length > 0 ? parts.join('=') : null;
        if (value) {
          result[key] = decodeURIComponent(value);
        }
      }
    }
    return result;
  }

  stringify(input: StringMap) {
    let encoded: string[] = [];
    for (let key in input) {
      // SWA begin
      const value = input[key];
      if (input.hasOwnProperty(key) && value) {
        this.addParameters(encoded, key, value);
      }
      // SWA end
    }
    return encoded.join('&');
  }

  addParameters(encoded: string[], key: string, value: string) {
    if (this.isMultiValueParameter(key)) {
      const values = value.split(',');
      for (const value of values) {
        this.addParameter(encoded, key, value);
      }
    } else {
      this.addParameter(encoded, key, value);
    }
  }

  addParameter(encoded: string[], key: string, value: string) {
    encoded.push(this.encodeParameter(key, value));
  }

  isMultiValueParameter(key: string): boolean {
    return MULTI_VALUE_PARAMETERS.includes(key);
  }

  encodeParameter(key: string, value: string): string {
    return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  }
}
