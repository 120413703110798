'use strict';
Object.defineProperty(exports, '__esModule', { value: true });

/**
 * Represents an Access Token request.
 * For more information see https://tools.ietf.org/html/rfc6749#section-4.1.3
 */
import { StringMap } from '../types';

export interface IntrospectionRequestJson {
  client_id: string;
  client_secret: string;
  token: string;
}

export class IntrospectionRequest {
  clientId: string;
  clientSecret: string;
  token: string;

  constructor(request: IntrospectionRequestJson) {
    this.clientId = request.client_id;
    this.clientSecret = request.client_secret;
    this.token = request.token;
  }

  toJson(): IntrospectionRequestJson {
    return {
      client_id: this.clientId,
      client_secret: this.clientSecret,
      token: this.token,
    };
  }

  toStringMap(): StringMap {
    return {
      client_id: this.clientId,
      client_secret: this.clientSecret,
      token: this.token,
    };
  }
}
