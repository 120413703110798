/**
 * Represents the UserInfoResponse as a JSON Object.
 */
export interface UserInfoResponseJson {
  sub: string;
  swaTitleDesc: string;
  email_verified: boolean;
  swaLocation: string;
  swaTitleCode: string;
  swaDeptDesc: string;
  groups: string;
  cn: string;
  preferred_username: string;
  given_name: string;
  swaPreferredFullName: string;
  swaDeptCode: string;
  family_name: string;
  email: string;
}

/**
 * Represents the User Info Response type.
 * For more information see https://tools.ietf.org/html/rfc6749#section-5.1
 */
export class UserInfoResponse {
  sub: string;
  swaTitleDesc: string;
  swaTitleCode: string;
  emailVerified: boolean;
  swaLocation: string;
  swaDeptDesc: string;
  groups: string;
  cn: string;
  preferredUserName: string;
  givenName: string;
  swaPreferredFullName: string;
  swaDeptCode: string;
  familyName: string;
  email: string;

  constructor(response: UserInfoResponseJson) {
    this.sub = response.sub;
    this.swaTitleDesc = response.swaTitleDesc;
    this.swaTitleCode = response.swaTitleCode;
    this.emailVerified = response.email_verified;
    this.swaLocation = response.swaLocation;
    this.swaDeptDesc = response.swaDeptDesc;
    this.groups = response.groups;
    this.cn = response.cn;
    this.preferredUserName = response.preferred_username;
    this.givenName = response.given_name;
    this.swaPreferredFullName = response.swaPreferredFullName;
    this.swaDeptCode = response.swaDeptCode;
    this.familyName = response.family_name;
    this.email = response.email;
  }

  toJson(): UserInfoResponseJson {
    return {
      sub: this.sub,
      swaDeptCode: this.swaDeptCode,
      swaDeptDesc: this.swaDeptDesc,
      swaLocation: this.swaLocation,
      swaPreferredFullName: this.swaPreferredFullName,
      swaTitleDesc: this.swaTitleDesc,
      email: this.email,
      email_verified: this.emailVerified,
      cn: this.cn,
      family_name: this.familyName,
      groups: this.groups,
      preferred_username: this.preferredUserName,
      given_name: this.givenName,
      swaTitleCode: this.swaTitleCode,
    };
  }
}

/**
 * Represents the UserInfoError as a JSON Object.
 */
export interface UserInfoErrorJson {
  error: UserInfoErrorType;
  error_description?: string;
  error_uri?: string;
}
/**
 * Represents the User Info Error type.
 * For more information see https://tools.ietf.org/html/rfc6749#section-5.2
 */
export class UserInfoError {
  error: UserInfoErrorType;
  errorDescription: string | undefined;
  errorUri: string | undefined;

  constructor(error: UserInfoErrorJson) {
    this.error = error.error;
    this.errorDescription = error.error_description;
    this.errorUri = error.error_uri;
  }

  toJson(): UserInfoErrorJson {
    return {
      error: this.error,
      error_description: this.errorDescription,
      error_uri: this.errorUri,
    };
  }
}

/**
 * Represents the possible error codes from the user info endpoint.
 * For more information see https://tools.ietf.org/html/rfc6749#section-5.2
 */
export type UserInfoErrorType = 'invalid_request' | 'invalid_client' | 'invalid_grant' |
  'unauthorized_client' | 'unsupported_grant_type' | 'invalid_scope';
