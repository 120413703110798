import { AuthorizationRequestHandler, Crypto, DefaultCrypto, FetchRequestor, OAuth, QueryStringUtils } from '..';
import { NodeCrypto } from '../node_support';
import { NodeRequestor } from '../node_support/node_requestor';
import { NodeBasedHandler } from '../node_support/node_request_handler';
import { RedirectRequestHandler } from '../redirect_based_handler';
import { BaseTokenRequestHandler } from '../token_request_handler';
import { JQueryRequestor, Requestor } from '../xhr';
import { BaseIntrospectionRequestHandler } from './introspection_request_handler';
import { NoHashQueryStringUtils } from './query_string_utils';
import { BaseUserInfoRequestHandler } from './userinfo_request_handler';
/*
 * Builder for the SWA OAuth client library interface.
 *
 * Rules:
 * 
 * - Browser applications should use the JQueryRequestor or the FetchRequestor, and the RedirectRequestHandler.
 * - NodeJS applications should use the NodeRequestor, and the NodeBasedHandler.
 * - ReactJS applications should use the FetchRequestor, and the NoHashQueryStringUtils.
 * - PKCE will be used by default. This can be overriden.
 */
abstract class AbstractOAuthBuilder {
  protected requestor: Requestor;
  protected readonly authorizationRequestHandler: AuthorizationRequestHandler;
  private usePkce: boolean = true;
  private readonly crypto: Crypto;

  constructor(requestor: Requestor, authorizationRequestHandler: AuthorizationRequestHandler, crypto: Crypto) {
    this.requestor = requestor;
    this.authorizationRequestHandler = authorizationRequestHandler;
    this.crypto = crypto;
  }

  withUsePkce(usePkce: boolean) {
    this.usePkce = usePkce;
    return this;
  }

  build(
    clientId: string,
    clientSecret: string | undefined,
    openIdConnectUrl: string) {

    return new OAuth(
      clientId,
      clientSecret,
      openIdConnectUrl,
      this.requestor,
      this.authorizationRequestHandler,
      new BaseTokenRequestHandler(this.requestor),
      new BaseUserInfoRequestHandler(this.requestor),
      new BaseIntrospectionRequestHandler(this.requestor),
      this.usePkce,
      this.crypto
    );
  }

}

/**
 * Build OAuth API for web applications.
 * 
 * Use JQuery for XMLHttpRequests by default.
 */
export class OAuthBuilder extends AbstractOAuthBuilder {

  constructor() {
    super(new JQueryRequestor(), new RedirectRequestHandler(), new DefaultCrypto());
  }

  /**
   * Use the fetch API for XMLHttpRequests. Necessary for React applications.
   */
  withFetchRequestor() {
    return this.withRequestor(new FetchRequestor());
  }

  withRequestor(requestor: Requestor) {
    this.requestor = requestor;
    return this;
  }

  /**
   * Use the no hash query parser. Necessary for React applications.
   */
  withNoHashQueryStringUtils() {
    return this.withQueryStringUtils(new NoHashQueryStringUtils());
  }

  withQueryStringUtils(queryStringUtils: QueryStringUtils) {
    this.authorizationRequestHandler.utils = queryStringUtils;
    return this;
  }
}

/**
 * Build OAuth API for NodeJS applications.
 * 
 * Use Node.js specific request handling.
 */
 export class NodeJSOAuthBuilder extends AbstractOAuthBuilder {

  constructor() {
    super(new NodeRequestor(), new NodeBasedHandler(), new NodeCrypto());
  }
}
